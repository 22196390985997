<template>
  <div ref="ccvideo" class="video-play" @click="handleClickVideoBox">
    <div ref="playRef" class="video-play__view"></div>
    <img
      v-if="captureImg && showCapture"
      :src="captureImg"
      class="capture-img"
      refs="cover"
      @click="handlePlay"
    />
  </div>
</template>

<script>
import { componentsCommon } from '@speedshop/template';
import { useBasic } from '@speedshop/sdk-api';
import uaParser from 'ua-parser-js';
import { getVideoInfo } from '@/graphql/common.gql';
export default {
  name: 'VideoPlayer',
  mixins: [componentsCommon],
  props: {
    video: {
      type: [Array, String],
      default: undefined
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    muteAutoPlay: {
      type: Boolean,
      default: false
    },
    captureImg: {
      type: String
    },
    loop: {
      type: Boolean,
      default: false
    },
    ccVideoOptions: {
      default: () => ({}),
      type: Object
    },
    parent: {
      type: String
    },
    onPlay: {}
  },
  data() {
    return {
      idx: 0,
      player: undefined,
      played: false,
      showCapture: true,
      timer: null,
      loadTimer: null,
      readyTimer: null,
      isQQ: false
    };
  },
  watch: {
    player(val) {
      if (!this.videoInfo || !val) return;
      this.player.video_id = this.videoInfo.id;
      this.player.video_name = this.videoInfo.title;
      this.player.video_duration = this.videoInfo.video_duration;
    }
  },
  mounted() {
    this.getDevice();
    const vid = Array.isArray(this.video) ? this.video[this.idx] : this.video;
    this.setPlayerCallBack();
    if (window.ccH5PlayerJsLoaded) {
      this.createPlayer();
    } else {
      this.loadTimer = setInterval(() => {
        if (window.ccH5PlayerJsLoaded) {
          this.createPlayer();
          clearInterval(this.loadTimer);
          this.loadTimer = null;
        }
      }, 1000);
    }
    this.getVideoInfo(vid);
  },
  beforeDestroy() {
    this.quitVideo();
    clearInterval(this.timer);
    clearInterval(this.loadTimer);
    clearInterval(this.readyTimer);
    this.player.destroy();
  },
  methods: {
    getDevice() {
      const parser = uaParser(navigator.userAgent);
      this.isQQ = parser.browser?.name === 'QQBrowser';
    },
    initTrack() {
      function track() {
        window.playerList.forEach(player => {
          //
          const PlayedPausedTimes = (player && player.getPlayedPausedTimes()) || { playedTimes: 0 };
          const playDuration = player.getPosition();
          // eslint-disable-next-line camelcase
          const video_id = player.video_id;
          // eslint-disable-next-line camelcase
          const video_name = player.video_name;
          // eslint-disable-next-line camelcase
          const video_duration = player.video_duration;
          this.$trackHelp.track('VideoQuit', {
            belong_page: this.$route.meta.title,
            video_id,
            video_name,
            video_duration,
            play_duration: playDuration,
            // eslint-disable-next-line camelcase
            is_finish: PlayedPausedTimes.playedTimes > video_duration * 0.9,
            watch_time: PlayedPausedTimes.playedTimes,
            // eslint-disable-next-line camelcase
            playback_progress: (playDuration / video_duration) * 100
          });
        });
      }
      document.addEventListener('visibilitychange', function () {
        // 用户息屏、或者切到后台运行 （离开页面）
        if (document.visibilityState === 'hidden') {
          track();
        }
      });
      // 刷新
      window.addEventListener('beforeunload', () => {
        track();
      });
      window.beforeunload = e => {
        //
      };
    },
    stopOtherVideo() {
      // 当播放的时候暂停其他播放器
      const videos = document.getElementsByTagName('video');
      Array.from(videos).forEach(el => {
        el.pause && el.pause();
      });
    },
    observer() {
      const element = this.$refs.ccvideo;
      console.log(element.parentNode, 999);
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach((entry, index) => {
            if (entry.intersectionRatio <= 0) {
              this.player.autoPause = true;
              this.player && this.player.pause && this.player.pause();
              console.log(55555);
            } else if (entry.intersectionRatio >= 0.5) {
              this.showCapture = false;
              console.log(4444);
              this.stopOtherVideo(this.player.video_id);
              if (this.player.handlePaused) {
                return;
              }
              this.player && this.player.play();
              console.log('play');
            }
          });
        },
        {
          threshold: [0, 0.5],
          rootMargin: '20px'
        }
      );

      setTimeout(() => {
        observer.observe(element);
      }, 1000);
    },
    handlePlay() {
      this.onPlay && this.onPlay();
      if (this.player) {
        this.stopOtherVideo(this.player.video_id);
        this.player.play && this.player.play();
        this.showCapture = false;
        this.player.autoPlay = false;
      } else {
        this.timer = setInterval(() => {
          if (this.player) {
            this.stopOtherVideo(this.player.video_id);

            this.player.paly && this.player.play();
            this.showCapture = false;
            clearInterval(this.timer);
            this.timer = null;
            this.player.autoPlay = false;
          }
        });
      }
    },
    handleClickVideoBox(event) {
      const el = event.target;
      console.log(el.className, 333);
      if (el.className !== 'ccH5TogglePause') return;
      const state = this.player && this.player.getVideoState();
      if (state === 'pause') {
        this.player.handlePaused = true;
      }
    },
    async createPlayer() {
      if (this.idx >= this.video?.length) this.idx = 0;
      const vid = Array.isArray(this.video) ? this.video[this.idx] : this.video;
      const player = await window.createCCH5Player({
        vid,
        siteid: '25514C0A4CE9E23F',
        width: '100%',
        height: '100%',
        mediatype: 1,
        realAutoPlay: this.autoPlay,
        autoStart: this.autoPlay,
        showHdBtn: false,
        isShowUrlBtn: false,
        closeHistoryTime: 1,
        parentNode: this.$refs.playRef,
        // loadingPic: this.captureImg,
        loop: this.autoPlay,
        muted: this.muteAutoPlay || !this.isMobile,
        ...this.ccVideoOptions
      });
      if (window.playerList) {
        window.playerList.push(player);
      } else {
        window.playerList = [];
        window.playerList.push(player);
      }

      this.idx++;
      this.player = player;

      if (this.videoInfo) {
        this.player.video_id = this.videoInfo.id;
        this.player.video_name = this.videoInfo.title;
        this.player.video_duration = this.videoInfo.video_duration;
      }
      window[vid] = player;
      if (this.autoPlay) {
        player.autoPlay = true;
        if (window.readyList && window.readyList[vid]) {
          setTimeout(() => {
            this.$trackHelp.track('VideoPlay', {
              belong_page: this.$route.meta.title,
              video_id: player.video_id,
              video_name: player.video_name,
              video_duration: player?.getDuration?.(),
              play_method: 'auto',
              is_success: true
            });
            this.observer();
          }, 100);
        } else {
          if (this.readyTimer) {
            clearInterval(this.readyTimer);
          }
          this.readyTimer = setInterval(() => {
            if (window.readyList && window.readyList[vid]) {
              this.$nextTick(() => {
                this.$trackHelp.track('VideoPlay', {
                  belong_page: this.$route.meta.title,
                  video_id: player.video_id,
                  video_name: player.video_name,
                  video_duration: player?.getDuration?.(),
                  play_method: 'auto',
                  is_success: true
                });
                this.observer();
              });
              clearInterval(this.readyTimer);
              this.readyTimer = null;
            }
          }, 1000);
        }
      }
    },
    nextPlayer() {
      this.player?.destroy();
      this.createPlayer();
    },
    setPlayerCallBack() {
      // window.onCCH5PlayerLoaded = this.onCCH5PlayerLoaded;
      window.on_CCH5player_ready = this.onPlayerReady;
      window.on_player_volumechange = this.onPlayerVolumeChange;
      window.on_CCH5player_play = this.on_CCH5player_play;
      window.on_CCH5player_ended = this.on_CCH5player_ended;
      window.on_CCH5player_pause = this.on_CCH5player_pause;
      window.on_player_seek = this.on_player_seek;
    },
    on_CCH5player_play(video, vid) {
      const current = window.playerList.find(i => i.video_id === vid);
      if (current.played) return;
      this.$trackHelp.track('VideoPlay', {
        belong_page: this.$route.meta.title,
        video_id: current.video_id,
        video_name: current.video_name,
        video_duration: current?.getDuration?.(),
        play_method: current.autoPlay && !current.played ? 'auto' : 'click play',
        is_success: true
      });
      current.played = true;
    },
    on_CCH5player_pause(video, vid) {
      console.log(vid, 888);
      const current = window.playerList.find(i => i.video_id === vid);
      if (current.pending) return;
      current.pending = true;
      if (current && current.autoPause) {
        current.autoPause = false;
      } else {
        this.$trackHelp.track('VideoOperate', {
          belong_page: this.$route.meta.title,
          video_id: current.video_id,
          video_name: current.video_name,
          video_duration: current?.getDuration?.(),
          click_content: '暂停'
        });
      }
      current.pending = false;
    },
    on_CCH5player_ended(video, vid) {
      //
      console.log('播放回调', video, vid);
    },
    on_player_seek(obj, vid) {
      //
      console.log(obj, vid, window.playerList);
      const current = window.playerList.find(i => {
        console.log(i, typeof i, 'i');
        return i.video_id === vid;
      });
      console.log(current, 'current');
      if (!current) return;
      this.$trackHelp.track('VideoOperate', {
        belong_page: this.$route.meta.title,
        video_id: current.video_id,
        video_name: current.video_name,
        video_duration: current?.getDuration?.(),
        click_content: '快进'
      });
    },
    onCCH5PlayerLoaded(obj) {
      console.log(obj, 'ccvideo: ccH5PlayerJsLoaded');
      // window.ccH5PlayerJsLoaded = true;
      // this.createPlayer();
    },
    onPlayerReady(obj) {
      const vid = obj.vid;
      setTimeout(() => {
        if (window.readyList) {
          window.readyList[vid] = true;
        } else {
          window.readyList = {};
          window.readyList[vid] = true;
        }
        window.ccH5PlayerIsReady = true;
      }, 1000);
    },
    onPlayerVolumeChange(volume, vid) {
      // 设置音量回
    },
    async getVideoInfo() {
      // getVideoInfo
      const basic = useBasic();
      const {
        data: { data }
      } = await basic.gqlClient.query(getVideoInfo, { videoid: this.video });
      this.videoInfo = data;
      if (!this.player) return;
      this.player.video_id = this.videoInfo.id;
      this.player.video_name = this.videoInfo.title;
      this.player.video_duration = this.videoInfo.video_duration;
    },
    quitVideo() {
      const PlayedPausedTimes = this.player && this.player.getPlayedPausedTimes();
      const playDuration = this.player.getPosition();
      const videoDuration = this.player?.getDuration?.();
      this.player?.destroy();
      this.$trackHelp.track('VideoQuit', {
        belong_page: this.$route.meta.title,
        video_id: this.videoInfo.id,
        video_name: this.videoInfo.title,
        video_duration: videoDuration,
        play_duration: playDuration,
        is_finish: PlayedPausedTimes.playedTimes > videoDuration * 0.9,
        watch_time: PlayedPausedTimes.playedTimes,
        playback_progress: (playDuration / videoDuration) * 100
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.video-play {
  width: 300px;
  height: 180px;
  position: relative;
  .capture-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 9;
  }
  video {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; /* Chrome 和 Safari */
    -moz-backface-visibility: hidden; /* Firefox */
    -ms-backface-visibility: hidden; /* Internet Explorer */
    -webkit-perspective: 0;
    -webkit-transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
.video-play__view {
  width: 100%;
  height: 100%;
}
</style>
<style>
.ccH5playerBox {
  position: initial !important;
  z-index: 0 !important;
}
.ccH5playerBox video {
  z-index: 9 !important;
  position: initial !important;
}
.ccH5Poster {
  z-index: 9 !important;
}
.ccH5AudioBg {
  z-index: 12 !important;
}
section.ccH5FadeOut {
  z-index: 13 !important;
}
#ccH5historyTimeBox {
  z-index: 14 !important;
}
.ccH5PlayBtn {
  z-index: 9 !important;
}
.ccMask {
  z-index: 15 !important;
}
.cch5SpeedTips {
  z-index: 16 !important;
}
.ccH5Info {
  z-index: 17 !important;
}
.ccH5Loading {
  z-index: 17 !important;
}
.ccH5More {
  z-index: 18 !important;
}
.ccH5MouseMenu .ccH5NetInfo {
  z-index: 19 !important;
}
.ccH5MouseMenu {
  z-index: 19 !important;
}
</style>
